<template>
  <v-row justify="center">
    <v-dialog
      origin="top center"
      v-model="customerDialog"
      :max-width="dialogWidth"
      content-class="customer-dialog"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline dialog-custom-header white-background custom-border-bottom"
        >
          <h4 class="custom-header-blue-text font-size-30">
            Select a Customer
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon dark color="cyan" v-on:click="$emit('resetAll', true)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-4 px-0 font-size-16">
          <v-container>
            <v-container fluid class="pt-0">
              <h5 class="font-weight-600 pb-4 mx-2 mx-md-4">
                Which customer would you like to create this for?
              </h5>
              <table width="100%">
                <tr>
                  <td>
                    <v-text-field
                      ref="search-customer"
                      v-model.trim="customer"
                      :loading="searchLoader"
                      :background-color="
                        !isFocused ? 'grey lighten-3' : undefined
                      "
                      :flat="!isFocused && !isSearching"
                      :placeholder="placeholder"
                      autocomplete="off"
                      class="mx-2 mx-md-4 rounded-lg"
                      dense
                      hide-details
                      solo
                      @blur="isFocused = false"
                      @focus="isFocused = true"
                      @keydown.esc="onEsc"
                      @keydown.enter="onEnter"
                      @keydown.tab="onTab"
                    >
                      <template #prepend-inner>
                        <v-icon
                          :color="!isFocused ? 'grey' : undefined"
                          class="ml-1 mr-2"
                        >
                          mdi-magnify
                        </v-icon>
                      </template>
                    </v-text-field>
                  </td>
                  <!--   <template v-if="getPermission('customer:create')">
                    <td width="50" class="custom-header-blue-text text-center">
                      OR
                    </td>
                    <td>
                      <v-btn
                        class="mx-2 mx-md-4 custom-bold-button white--text"
                        v-on:click="createCustomerDialog = true"
                        color="cyan"
                      >
                        <v-icon dark left>mdi-plus</v-icon>Customer
                      </v-btn>
                    </td>
                  </template> -->
                </tr>
              </table>

              <div class="pt-4">
                <perfect-scrollbar
                  :options="{ suppressScrollX: true }"
                  class="scroll"
                  style="max-height: 90vh; position: relative"
                >
                  <div class="mx-2 mx-md-4">
                    <table
                      width="100%"
                      style="table-layout: fixed"
                      class="table-hover"
                    >
                      <tr
                        v-for="(data, index) in customerList"
                        v-on:click="selectCustomer(data)"
                        :key="`parent-${index}`"
                      >
                        <td width="10%" v-if="false">
                          <v-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(data.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto"
                              transition="fade-transition"
                            ></v-img>
                          </v-avatar>
                        </td>
                        <td width="70%">
                          <div class="d-flex">
                            <Barcode :barcode="data.barcode"></Barcode>
                            <v-chip
                              color="blue white--text"
                              label
                              small
                              class="ml-1 mt-1"
                              style="height: 26px"
                              v-if="data.company_type == 'company'"
                            >
                              <span class="font-size-16 font-weight-500">
                                Company
                              </span>
                            </v-chip>
                            <v-chip
                              color="red white--text"
                              label
                              class="ml-1 mt-1"
                              style="height: 26px"
                              v-if="data.company_type == 'personal'"
                            >
                              <span class="font-size-16 font-weight-500">
                                Individual
                              </span>
                            </v-chip>
                          </div>
                          <p class="mb-0 font-weight-500 font-size-16">
                            <v-icon class="chip-custom-blue" small
                              >mdi-bag-checked</v-icon
                            >
                            {{ data.display_name }}
                          </p>
                          <p class="mb-0 font-weight-500 font-size-16">
                            <v-icon class="chip-custom-blue" small
                              >mdi-account</v-icon
                            >
                            {{ getDefaultPerson(data.default_person) }}
                          </p>
                          <p
                            class="mb-0 font-weight-500 font-size-16"
                            v-if="data.single_billing"
                          >
                            <v-icon class="chip-custom-blue" small
                              >mdi-map-marker-outline</v-icon
                            >
                            {{
                              data.single_billing &&
                              data.single_billing.street_1
                            }}
                            {{ data.single_billing.street_2 }}
                            {{ data.single_billing.zip_code }}
                          </p>
                        </td>
                        <td width="20%" align="left">
                          <p class="mb-0 font-weight-500 font-size-13">
                            {{ data.property_count }} Site Location(s)
                          </p>
                          <p class="mb-0 font-weight-500 font-size-13">
                            {{ data.persons_count }} Contact Person(s)
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </perfect-scrollbar>
              </div>
            </v-container>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <Dialog
      v-if="createCustomerDialog"
      :commonDialog="createCustomerDialog"
      :dialogWidth="1024"
    >
      <template v-slot:title>Create Customer</template>
      <template v-slot:body>
        <v-form
          ref="createCustomerOnly"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="createCustomerOnly"
        >
          <DialogContactDetails
            :isDialog="true"
            v-on:saveContactDetail="updateCustomerDetailOnly"
          >
          </DialogContactDetails>
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="createCustomerOnly"
          >Save Customer
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="createCustomerDialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-row>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
import CustomerMixin from "@/core/plugins/customer-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { POST, CLEAR_ERROR } from "@/core/services/store/request.module";
import DialogContactDetails from "@/view/pages/customer/create-or-update/Contact-Details.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";

export default {
  mixins: [CustomerMixin],
  data() {
    return {
      formValid: true,
      formLoading: false,
      isFocused: false,
      searchLoader: true,
      customer: null,
      timeoutLimit: 500,
      timeout: null,
      customerArr: new Object(),
      createCustomerDialog: false,
      customerList: new Array(),
    };
  },
  props: {
    customerDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 768,
    },
    entity: {
      type: Number,
      default: 0,
    },
  },
  components: {
    Dialog,
    Barcode,
    DialogContactDetails,
  },
  watch: {
    customer(param) {
      if (!param) {
        const _this = this;
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          _this.searchCustomer();
        }, _this.timeoutLimit);
      }
    },
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    onTab() {
      if (this.isSearching) {
        this.searchCustomer();
      }
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.customer = null;
        _this.isFocused = false;
      });
    },
    searchCustomer() {
      const _this = this;
      _this.searchLoader = true;
      _this
        .getCustomers(_this.customer, _this.entity)
        .then((response) => {
          _this.customerList = response;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searchLoader = false;
          _this.createCustomerDialog = false;
        });
    },
    selectCustomer(param) {
      if (
        param.persons_count === 1 &&
        this.lodash.isEmpty(param.single_person) === false
      ) {
        this.$emit("selectCustomerPerson", param.single_person.id);
      }
      if (
        param.property_count === 1 &&
        this.lodash.isEmpty(param.single_property) === false
      ) {
        this.$emit("selectCustomerProperty", param.single_property.id);
      }
      if (
        param.billing_count === 1 &&
        this.lodash.isEmpty(param.single_billing) === false
      ) {
        this.$emit("selectCustomerBilling", param.single_billing.id);
      }
      this.$emit("selectCustomer", param.id);
    },
    getProfileImage(imagePath) {
      if (!imagePath) {
        return this.$filePath + "storage/uploads/images/no-image.png";
      }
      return this.$filePath + imagePath;
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    updateCustomerDetailOnly(param) {
      this.customerArr = param;
    },
    createCustomerOnly() {
      const _this = this;
      if (!_this.$refs.createCustomerOnly.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      const formData = {
        title: _this.customerArr.salutation,
        display_name: _this.customerArr.display_name,
        company_name: _this.customerArr.company_name,
        profile_logo: _this.customerArr.profile_logo,
        first_name: _this.customerArr.first_name,
        last_name: _this.customerArr.last_name,
        group_id: _this.customerArr.group_id,
        primary: +_this.customerArr.primary,
        details: _this.customerArr.details,
      };

      _this.$store
        .dispatch(POST, { url: "customer-only", data: formData })
        .then(() => {
          _this.searchCustomer();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  mounted() {
    this.searchCustomer();
    this.$refs["search-customer"].focus();
  },
  computed: {
    isSearching() {
      return this.customer && this.customer.length > 0;
    },
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search Customer...";
    },
    getDefaultImage() {
      return this.$filePath + "storage/uploads/images/no-image.png";
    },
  },
};
</script>
