var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"custom-phone-input"},[_c('v-flex',{staticClass:"country-code"},[_c('v-select',{attrs:{"dense":"","filled":"","label":"Country","solo":"","flat":"","items":_vm.countryList,"hide-details":"","color":"cyan","disabled":_vm.disabled,"loading":_vm.loading,"item-text":"name","item-value":"iso2","item-color":"cyan","return-object":""},on:{"change":_vm.updateActiveCountry},scopedSlots:_vm._u([{key:"selection",fn:function(){return [_c('div',{staticClass:"vti__flag mr-2",class:_vm.activeCountry.iso2.toLowerCase()})]},proxy:true},{key:"item",fn:function(data){return [_c('span',{staticClass:"vti__flag mr-2",class:data.item.iso2.toLowerCase()}),_c('span',[_vm._v(_vm._s(data.item.name)+" "+_vm._s(`+${data.item.dialCode}`))])]}}]),model:{value:(_vm.countryCode),callback:function ($$v) {_vm.countryCode=$$v},expression:"countryCode"}})],1),_c('v-flex',{staticClass:"width-100"},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],key:_vm.phoneKey,attrs:{"id":_vm.id,"rules":_vm.rules,"dense":"","filled":"","disabled":_vm.disabled,"loading":_vm.loading || _vm.numberLoading,"label":_vm.label,"solo":"","flat":"","color":"cyan","hide-details":_vm.hideDetails},on:{"keyup":function($event){_vm.$emit('input', _vm.phone);
        _vm.$emit('change', _vm.phone);
        _vm.validateNumber(_vm.phone);},"change":function($event){_vm.$emit('input', _vm.phone);
        _vm.$emit('change', _vm.phone);
        _vm.validateNumberMala(_vm.phone);},"blur":function($event){_vm.$emit('input', _vm.phone);
        _vm.$emit('change', _vm.phone);},"focus":function($event){_vm.$emit('input', _vm.phone);
        _vm.$emit('change', _vm.phone);}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),(_vm.invalidNumber)?[_c('span',{staticStyle:{"color":"red","font-weight":"600"}},[_vm._v(_vm._s(_vm.invalidNumber))])]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }